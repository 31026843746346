@import "../../global.scss";

.intro{
    background-color: whitesmoke;
    display: flex;

    @include mobile{
        flex-direction: column;
        align-items: center;
      }

    .left{
        flex: 0.5;
        background-color: $mainColor;
        background-image: linear-gradient($mainColor, #120234);
        overflow: hidden;

        .imgContainer{
            width: 700px;       /*box containing the image*/
            height: 700px;
            margin-top: 35%;
            background-color: whitesmoke;
            border-radius: 50%;
            display: flex;
            align-items: flex-end;
            justify-content: left;
            float: right;  /*item placed from the right side of the divider column */
            
            @include mobile{
                align-items: flex-start;
                justify-content: space-around;
                margin-top: 1%;
                width: 95%;
                height: 95%;
                z-index: 0;
                overflow-y: hidden;
              }

            img{
                width: 80%;     /*image size*/

                @include mobile{
                    width: 75%;
                    z-index: 0;
                    scrollbar: hidden;
                  }
            }
        }
    }
    .right{
        flex: 0.5;
        position: relative;
        overflow-y: hidden;
        @include mobile{
        overflow-y: hidden;
        }

        .wrapper{
           width: 100%;
           height: 100%;
           padding-left: 50px;
           display: flex;
           flex-direction: column;
           justify-content: center;

           @include mobile{
            padding-left: 0;
            align-items: center;
          }

           h1{
            font-size: 60px;
            margin: 10px 0;

            @include mobile{
                font-size: 40px;
              }

           }
            
           h2{
               font-size: 35px;
           }
           h3{
               font-size: 30px;

               @include mobile{
                font-size: 20px;
              }

               span{
                   font-size: inherit;
                   color: $tiniaryColor;
               }
               /*settings for the curser in the animation*/
               .ityped-cursor {
                font-size: 2.2rem;
                opacity: 1;
                -webkit-animation: blink 0.3s infinite;
                -moz-animation: blink 0.3s infinite;
                animation: blink 1s infinite;
                animation-direction: alternate;
            }
            /*settings for the curser in the animation*/
            @keyframes blink {
                100% {
                    opacity: 0;
                }
            }

           }
        }
        a{
            position: relative;
            bottom: 10px;
            left: 40%;
            img{
                width: 30px;
                animation: arrowBlink 2s infinite;
            }

            @keyframes arrowBlink{
                100%{
                    opacity: 0;
                }
            }
        }
    }
}