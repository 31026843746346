@import "../../global.scss";
:root{
  --primary: hsla(0, 0%, 100%, 0);
  --secondary: hsla(0, 0%, 100%, 0);
  --tiniary: rgb(255, 255, 255);
}

.contact {
    background-color: white;
    display: flex;
  
    @include mobile {
      flex-direction: column;
    }
  
    .left {
      flex: 1;
      overflow: hidden;
      background: linear-gradient(90deg, var(--primary), var(--secondary), var(--tiniary)),
      url('./../../me_laptop.jpg') no-repeat center left / cover;

    }
    .right {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  
      h2 {
        font-size: 30px;
      }
  
      form {
        width: 70%;
        height: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
  
        input {
          width: 300px;
          height: 30px;
          font-size: 14px;
  
          @include mobile {
            width: 200px;
            height: 20px;
          }
        }
  
        textarea {
          width: 300px;
          height: 200px;
          font-size: 14px;
  
          @include mobile {
            width: 200px;
            height: 100px;
          }
        }
  
        button {
          width: 150px;
          height: 30px;
          color: white;
          background-color: $mainColor;
          border: none;
          border-radius: 10px;
          font-weight: 500;
          cursor: pointer;
  
          &:focus {
            outline: none;
          }
        }
  
        span {
          color: green;
        }
      }
    }
  }