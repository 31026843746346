@import "../../global.scss";

.portfolio{
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    h1{
        font-size: 50px;
        overflow: hidden;
        @include mobile{
            font-size: 20px;
        }   
    }

    ul{
        margin: 10px;
        padding: 0;
        list-style: none;
        display: flex;

        @include mobile{
            margin: 10px 0;
            flex-wrap: wrap;
            justify-content: center;
            padding: 1px;
            overflow-y: hidden;
          }

        li{
            font-size: 12px;
            margin-right: 20px;
            padding: 7px;
            border-radius: 10px;
            cursor: pointer;
            overflow: hidden;

            @include mobile{
                padding: 5px;
            }

            &.active{
                background-color: $mainColor;
                color: white;

            }
        }
    }
    .container{
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        @include mobile{
            width: 100%;
          }

        .item{
            width: 220px;
            height: 150px;
            border-radius: 20px;
            border: 1px solid #E9E2EC;
            margin: 40px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            position: relative;
            transition: all .5s ease;
            cursor: pointer;
            overflow: hidden;

            @include mobile{
                width: 130px;
                height: 100px;
                margin: 10px 10px;
              }

            h3{
                position: absolute;
                font-size: 20px;
            }

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: 1;
                overflow: hidden;
            }

            &:hover{
                overflow: hidden;
                background-color: white;
                img{
                    opacity: 0.2;
                    width: 200%;
                    height: 200%;
                    z-index: 0;
                }
                h3{
                    color: $mainColor;
                }
            }
        }
    }
}

