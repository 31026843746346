// .welcome{
//     position: fixed;
//     height: 100%;
//     z-index: 1000;
// }

.app{
    height: 100vh;
    
    .sections{
        width: 100%;
        height: calc(100vh - 70px);
        background-color: #ffa07a;
        position: relative;
        top: 70px;
        scroll-behavior: smooth;
        scroll-snap-type: y mandatory;
        scrollbar-width: none; //for firefox
        &::-webkit-scrollbar{
            display: none;
        }
        /*for each element inside sections*/
        > *{
            width: 100vw;
            height: calc(100vh - 70px);
            scroll-snap-align: start;
        }
    }
}