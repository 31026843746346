.popup-box {
	position: fixed;
	background-color: 'rgba(0,0,0,.7)';
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1000;
  }
   
  .box {
	position: relative;
	width: 70%;
	margin: 0 auto;
	height: auto;
	max-height: 70vh;
	margin-top: calc(100vh - 85vh - 20px);
	background: #fff;
	transform: 'translate(-50%, -50%)';
	border-radius: 4px;
	padding: 20px;
	border: 1px solid #999;
	overflow: auto;
	z-index: 1000;
  }
   
  .close-icon {
	content: 'x';
	cursor: pointer;
	position: fixed;
	right: calc(15% - 30px);
	top: calc(100vh - 85vh - 33px);
	background: #ededed;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	line-height: 20px;
	text-align: center;
	border: 1px solid #999;
	font-size: 20px;
	overflow: hidden;
  }