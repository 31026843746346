@import "../../global.scss";

.works{
    background-color: $mainColor;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .arrow{
        height: 50px;
        position: absolute;
        cursor: pointer;

        @include mobile {
            display: none;
          }

        &.left {
            left:  100px;
            transform: rotateY(180deg);
        }

        &.right {
            right: 100px;
        }
    }
    .slider{
        height: 350px;
        display: flex;
        position: absolute;
        left: 0;
        transition: all 1s ease-out;

        @include mobile {
            height: auto;
            flex-direction: column;
            justify-content: center;
          }
        
        .container{
            width: 100vw;
            display: flex;
            align-items: center;
            justify-content: center;

            .item{
                width: 700px;
                height: 100%; 
                background-color: white;
                border-radius: 20px;
                display: flex;
                align-items: center;

                @include mobile {
                    width: 80%;
                    height: 150px;
                    margin: 15px 0;
                  }

                .left{
                    height: 80%;
                    flex: 4;
                    background-color: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .leftContainer{
                        width: 90%;
                        height: 70%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        .imgContainer{
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            background-color: $subColor;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            
                            // @include mobile {
                            //     width: 20px;
                            //     height: 20px;
                            //   }

                            img{
                                width: 25px;
                            }
                            @include mobile{
                                width: 20px;
                                height: 20px;
                            }
                        }
                        h2{
                            font-size: 20px;

                            @include mobile{
                                font-size: 13px;
                              }
                        }
                        p{
                           font-size: 13px; 
                           @include mobile{
                               display: none;
                           }
                        }
                        
                        span{
                            font-size: 12px;
                            font-weight: 600;
                            text-decoration: underline;
                            cursor: pointer;
                        }
                    }
                }
                .right{
                    flex: 8;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: right;
                    overflow: hidden;
                    background-color: white;

                    img{
                        width: 400px;
                        // transform: rotate(-10deg);
                    }
                }
            }
        }
    }
}